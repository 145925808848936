/* global VsmReporter */
import { stratify } from 'd3-hierarchy';

import config from '../config';
import { defaultTitle } from './tree-renderer/config';

/**
 * Convert a screenshot URL into a thumb URL for sitemap tree.
 * @param {*} screenshot - Screenshot URL
 */
export const treeNodeThumbUrl = (screenshot) => {
  return screenshot.replace(/(.*)\/(.+\.\w{3,4})/, `$1/312x232/$2`);
};

/**
 * Convert a screenshot path into an absolute screenshot URL
 *
 * @param {*} url - url to work with
 * @param {*} baseScreenshotPath - path to prepend to URLs without protocol. Default: SCREENSHOT_PATH
 */
export const absoluteThumbUrl = (url, baseScreenshotPath = config.SCREENSHOT_PATH) => {
  let result = url;

  if (!/^http/.test(result) && baseScreenshotPath) {
    result = `${baseScreenshotPath}/${result}`;
  }

  return result;
};

// Check if parent property is defined. We use 0 for root node
const hasParent = (prop) => (n) => {
  const value = n[prop];

  return value === 0 || Boolean(value);
};

export const parentPresent = hasParent('parent');
export const altParentPresent = hasParent('altParent');

export const nodesToTree = (nodes, parentProp = 'parent') => {
  const hierarchy = stratify().parentId((node) => {
    const parentValue = node[parentProp];

    // In the old times we used parent id 0 to mark root.
    // Stratify treats that as a valid id and fails trying to search for that node on the list.
    if (parentValue === 0) {
      return '';
    }

    return parentValue;
  });

  return hierarchy(nodes);
};

/**
 * Given an object with the shape:
 * {
 *  "id_1": [1, 3, 4],
 *  "id_2": [3, 4]
 * }
 *
 * returns an object like:
 *
 * {
 *   1: ["id_1"],
 *   3: ["id_1", "id_2"],
 *   4: ["id_2"]
 * }
 */
export const inverseMap = (map, transformation) => {
  return Object.keys(map).reduce((result, key) => {
    const values = map[key];
    if (values?.length) {
      values.forEach((value) => {
        let inverse = result[value];
        if (!inverse?.length) {
          inverse = [];
          // eslint-disable-next-line no-param-reassign
          result[value] = inverse;
        }
        inverse.push(transformation(key));
      });
    }

    return result;
  }, {});
};

/**
 * Add all the elements to the map key. If key doesn't exits, initialize it with an empty list.
 * This method mutates `map` parameter
 *
 * examples:
 *
 * multiMapAdd({}, '1', ['a', 'b']) => { '1': ['a', 'b'] }
 * multiMapAdd({ '1': ['c'] }, '1', ['a', 'b']) => { '1': ['c', 'a', 'b'] }
 */
export const multiMapAdd = (map, key, elements) => {
  if (!map[key]) {
    // eslint-disable-next-line no-param-reassign
    map[key] = [];
  }

  const values = map[key];
  values.push(...elements);
};

/**
 * Remove all the elements from list under key parameter. If list is empty after the operation then key is deleted
 * from map.
 *
 * This method mutates `map` parameter
 *
 * examples:
 *
 * multiMapRemove({ '1': ['a', 'b', 'c'] }, '1', ['a', 'b']) => { '1': ['c'] }
 * multiMapRemove({ '1': ['a'] }, '1', ['a']) => {}
 */
export const multiMapRemove = (map, key, elements) => {
  const values = map[key];

  if (!values) {
    return;
  }

  elements.forEach((element) => {
    const idx = values.indexOf(element);
    if (idx >= 0) {
      values.splice(idx, 1);
    }
  });

  if (values.length === 0) {
    // eslint-disable-next-line no-param-reassign
    delete map[key];
  }
};

/**
 * Return the intersection of the given keys values.
 *
 * example:
 *
 * multiMapIntersection({ '1': ['a', 'b', 'c'], '2': ['c', 'd'], '3': ['e'] }, ['1', '2']) => ['c']
 */
export const multiMapIntersection = (map, keys) => {
  if (!keys || keys.length === 0) {
    return [];
  }

  if (keys.length === 1) {
    return map[keys] || [];
  }

  let result = new Set(map[keys[0]] || []);
  for (let idx = 1; idx < keys.length; idx += 1) {
    const key = keys[idx];
    const values = map[key] || [];

    // Intersection between result & values
    const intersection = new Set();
    for (let j = 0; j < values.length; j += 1) {
      const value = values[j];
      if (result.has(value)) {
        intersection.add(value);
      }
    }

    result = intersection;
  }

  return Array.from(result);
};

/**
 * Return the union of the given keys values.
 *
 * example:
 *
 * multiMapUnion({ '1': ['a', 'b', 'c'], '2': ['c', 'd'], '3': ['e'] }, ['1', '2']) => ['a', 'b', 'c', 'd']
 */
export const multiMapUnion = (map, keys) => {
  if (keys.length === 0) {
    return [];
  }

  const tags = new Set();

  keys.forEach((key) => {
    const values = map[key];
    if (values) {
      values.forEach((id) => tags.add(id));
    }
  });

  return Array.from(tags);
};

/**
 * Is the given event happening due to sitemap reload?
 *
 * @param {*} event - event to check
 * @returns true if the event was triggered due sitemap tree reload
 */
export const isReloadContext = (event) => {
  const {
    detail: { context },
  } = event;

  return context === 'reload';
};

export const nameOrDefault = (node) => node.name || defaultTitle;

export const sortNodeByName = (a, b) => {
  const { id: idA } = a;
  const { id: idB } = b;
  const nameA = nameOrDefault(a).toLowerCase();
  const nameB = nameOrDefault(b).toLowerCase();

  // Fist sort by name
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // Then sort by id
  if (idA < idB) {
    return -1;
  }
  if (idA > idB) {
    return 1;
  }

  return 0;
};

export const sortNodeByTypeAndName = (a, b) => {
  const typeA = a.isFolder ? 0 : 1;
  const typeB = b.isFolder ? 0 : 1;

  if (typeA !== typeB) {
    return typeA - typeB;
  }

  return sortNodeByName(a, b);
};

export const reportError = (e) => {
  if (typeof VsmReporter !== 'undefined') {
    VsmReporter.captureException(e);
  } else {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

/**
 * Returns an object whose keys are the elements of the collection. Each key has a thruty value.
 * Used to optimize inclusion.
 * Also used to support type coercion on lookups.
 * Node id can be a integer and a string, Node.id references (PageContent.pageId) are strings.
 * Having this function give us a workaround to check if exists a pageContent.pageId for a given node.id.
 */
export const castToTruthyHash = (collection) => {
  if (!collection) {
    return {};
  }

  const array = Array.from(collection); // Deal with Set
  return array.reduce((r, v) => {
    // eslint-disable-next-line no-param-reassign
    r[v] = true;
    return r;
  }, {});
};

export const makePageId = (node) => (node.isFolder ? '' : `n-${node.id}`);

export const isUrl = (str) => {
  let url;
  try {
    url = new URL(str);
  } catch {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const parseURL = (str) => {
  try {
    return new URL(str);
  } catch {
    return null;
  }
};

export const parseBaseURL = (str) => {
  const url = parseURL(str);
  if (url) {
    url.pathname = '';
  }

  return url;
};

export const triggerEvent = (name, data) => {
  document.dispatchEvent(new CustomEvent(name, { detail: data }));
};
