/* eslint-disable import/prefer-default-export, no-param-reassign */
import { reportError } from './utils';

function sortUrls(a, b) {
  const aURI = new URL(a.url);
  const bURI = new URL(b.url);
  const aParts = aURI.pathname.split('/').filter((u) => u !== '');
  const bParts = bURI.pathname.split('/').filter((u) => u !== '');

  const compareParts = Math.min(aParts.length, bParts.length);
  let comparison = 0;

  for (let i = 0; i < compareParts; i += 1) {
    if (aParts[i] !== bParts[i]) {
      comparison = aParts[i] > bParts[i] ? 1 : -1;
      break;
    }
  }

  if (comparison === 0 && aParts.length !== bParts.length) {
    comparison = aParts.length > bParts.length ? 1 : -1;
  }

  // If we have two pages with the same path, put first url without querystring
  // If all have querystring, sort them alphabetically
  if (comparison === 0) {
    const aRest = `${aURI.search}${aURI.hash}`;
    const bRest = `${bURI.search}${bURI.hash}`;
    comparison = aRest.localeCompare(bRest);
  }

  return comparison;
}

function nextListId(list) {
  return list[list.length - 1].id + 1;
}

/**
 * Rewrite an URL swapping http for https or vice-versa
 *
 * @param {string} url
 */
function swapProtocol(url) {
  if (url.startsWith('http://')) {
    return `https://${url.substring(7)}`;
  }

  if (url.startsWith('https://')) {
    return `http://${url.substring(8)}`;
  }

  return url;
}

function getNodeByUrl(url, nodeByUrl) {
  const node = nodeByUrl[url] || nodeByUrl[`${url}/`];
  if (node) {
    return node;
  }

  const otherProtocolUrl = swapProtocol(url);

  return nodeByUrl[otherProtocolUrl] || nodeByUrl[`${otherProtocolUrl}/`];
}

function findPageParentId(page, list, nodeByUrl) {
  let parentId = list[0].id; // by default, the parent is the root

  const url = new URL(page.url);
  const urlParts = url.pathname.split('/').filter((u) => u !== '');
  urlParts.pop(); // disregard the last part

  let fullPath = '';

  // TODO: Since pages are sorted, would be better to do a reverse iteration and stop once we found the first parent.
  // A way to do that is reverse iterate over url chars. On each / try to find a node. Once we find the first that exists we can stop
  // For now using an index give us enough performance gain (drop from 9s to less than a few ms)
  urlParts.forEach((part) => {
    // is this path part (parent) already in the list?
    fullPath += `/${part}`;
    const fullUrl = `${url.protocol}//${url.host}${fullPath}`;
    const parent = getNodeByUrl(fullUrl, nodeByUrl);

    if (parent) {
      // if it is, we just set it as the parentId
      parentId = parent.id;
    } else {
      // if not, then we create a folder/wrapper
      const node = {
        id: nextListId(list),
        pageId: fullPath,
        parent: parentId,
        path: fullPath,
        url: fullUrl,
        name: part,
        isFolder: true,
      };
      list.push(node);

      nodeByUrl[fullUrl] = node;
      parentId = node.id;
    }
  });

  return parentId;
}

function pageIsAFolder(page, pageIndex, childrenPages) {
  const baseUrl = `${page.url}/`.replace(/\/+$/, '/'); // make sure it has a trailing slash, but only one

  // childrenPages is sorted. If I'm a folder then next node must be one of my childrens
  const nextPage = childrenPages[pageIndex + 1];
  return nextPage && nextPage.url.includes(baseUrl);
}

function pagePath(page) {
  return new URL(page.url).pathname.trim();
}

function isRootPath(page) {
  const path = pagePath(page);

  return path === '/' || path === '';
}

function rootPageItem(rootPage) {
  return {
    id: 1,
    pageId: rootPage.id,
    parent: 0,
    path: '/',
    url: rootPage.url,
    // Keep record of parentUrl so that we can build referral tree.
    // Directory tree may not have same root node as referral tree
    parentUrl: rootPage.parent || '',
    name: rootPage.pageTitle,
    screenshot: rootPage.screenshotLink || rootPage.screenshotPath, // support old format
    thumbnail: rootPage.screenshotThumbLink, // support old format
  };
}

function findPage(pages, targetUrl) {
  return pages.find((p) => p.url === targetUrl);
}

function pickReferralRootPage(pages, rootUrl, redirects) {
  // Don't have to build referral tree. Bail out.
  if (!rootUrl) {
    return null;
  }

  let rootPage = findPage(pages, rootUrl);

  // When user crawls a site that starts with a redirect
  // the root doesn't appear in pages it's the first redirect
  if (!rootPage) {
    const rootRedirect = redirects.find((r) => !r.parentUrl);
    rootPage = findPage(pages, rootRedirect?.to);
  }

  // Fallback to isRoot flag
  if (!rootPage) {
    rootPage = pages.find((p) => p.isRoot === 'true');
  }

  return rootPage;
}

/**
 * Given a URL create a list of folders that represent the path to that URL.
 *
 * Example: rootFolderItem('https://example.com/a/b/c') will return a list of 3 folders: 'example.com', 'a' and 'b'
 */
function rootFolderItem(rootUrl) {
  const url = new URL(rootUrl);

  const urlParts = url.pathname
    .split('/')
    .filter((u) => u !== '')
    .slice(0, -1);

  let fullPath = '';
  const urlPartFolders = urlParts.map((part, idx) => {
    // is this path part (parent) already in the list?
    fullPath += `/${part}`;

    return {
      id: idx + 2,
      pageId: fullPath,
      parent: idx + 1,
      path: fullPath,
      url: `${url.protocol}//${url.host}${fullPath}`,
      name: part.substring(0, 500),
      isFolder: true,
    };
  });

  const rootFolder = {
    id: 1,
    pageId: '/',
    parent: 0,
    path: '/',
    url: `${url.protocol}//${url.host}`,
    name: url.host.substring(0, 500),
    isFolder: true,
  };

  return [rootFolder].concat(urlPartFolders);
}

/**
 * Given a list of redirects and a mapping of urls to nodes build a function that searchs for the node of the given referral URL.
 * The url maybe a redirect. This type of URLs are not pages. For them, we go back through the redirect chain until we find an existing node in the tree.
 *
 * Example:
 * If page A contains a link to B but B redirects to C, then sitemap only has pages for A and C but C's parentUrl is still B.
 *
 * This function deals with this and, since B is not a tree node goes back through the redirect chain and return A.
 *
 * @param {*} redirects - Redirections found by the crawler
 * @param {*} nodeByUrl - nodes for each page returned by the crawler indexed by url
 */
function refererFinder(redirects, nodeByUrl) {
  // Index redirect information
  const redirectParentUrl = {};
  redirects.forEach((redirect) => {
    const target = redirect.from;
    const source = redirect.parentUrl;
    redirectParentUrl[target] = source;
  });

  // Deals with situation were we should ignore the existing node in the tree.
  // Example: when redirect is also a folder
  const getNode = (url) => {
    const node = nodeByUrl[url];

    // Deal with situations where a redirect is also a folder.
    // In those situations we should keep looking. Folders are excluded from referral view.
    // For example:
    // https://www.dykeanddean.us has a link targeting https://www.dykeanddean.us/apps/tracktor. That URL redirects to https://www.dykeanddean.us/apps/tracktor/track.
    // Our path tree has a folder node for https://www.dykeanddean.us/apps/tracktor
    // Our referal tree should say that https://www.dykeanddean.us is the parent of https://www.dykeanddean.us/apps/tracktor/track
    // When root page redirects it end up represented as a folder. Instead of finding a new root for referral organization (complex) we decided to keep the root folder as an exception. Example, https://contractsrl.com redirects to https://contractsrl.com/en
    return node?.isFolder && node?.parent !== 0 ? undefined : node;
  };

  /**
   * @param {*} referralUrl - the url for which we are searching the node
   */
  return (referralUrl) => {
    let targetUrl = referralUrl;
    let node = getNode(referralUrl);
    let iterCount = 0; // to avoid stuck from infinite loops

    // We don't have an existing node but we have a url.
    // Lookup for the parent of that URL in the redirect index and exhaust the redirect chain
    while (!node && targetUrl) {
      targetUrl = redirectParentUrl[targetUrl];

      // After exhausting the redirect chain it's time to search for the node of the parent url.
      // Note that if parent is undefined (no url found) then node is gonna be undefined too and we exit the loop.
      if (targetUrl && !redirectParentUrl[targetUrl]) {
        node = getNode(targetUrl);
        // Note that if we don't find a node in the next iteration we are going to exit the while
      }
      iterCount += 1;
      if (iterCount === 100) {
        throw new Error(`Loop iteration count exceded while looking for '${referralUrl}`);
      }
    }
    if (node) {
      return node;
    }

    throw new Error(`No node found for '${referralUrl}`);
  };
}

function buildReferralTree(list, rootPage, nodeByUrl, redirects) {
  function clean() {
    // handling no node found
    list.forEach((item) => {
      item.altParent = '';
      delete item.parentUrl;
    });
  }

  const rootNode = getNodeByUrl(rootPage.url, nodeByUrl);
  if (!rootNode) {
    clean();

    return;
  }

  rootNode.altParent = 0;

  const findRefererByUrl = refererFinder(redirects, nodeByUrl);

  try {
    list.forEach((item) => {
      if (item.altParent === undefined && !item.isFolder) {
        // folder nodes do not have altParent
        const parentByReferral = findRefererByUrl(item.parentUrl);
        item.altParent = parentByReferral?.id || '';
      }
      // Parent URL is not needed anymore
      delete item.parentUrl;
    });
  } catch (err) {
    reportError(err);
    clean();
  }
}

function organizeByPath(pages, rootUrl, redirects) {
  if (pages.length === 0) {
    return [];
  }

  const list = [];
  const nodeByUrl = {};

  // pages are sorted so that folder paths are found "in order"
  // this way we hit /a before /a/a/a and we hit /a before /b
  // and we hit /a before /a?querystring
  const childrenPages = pages.slice().sort(sortUrls);

  // Our algorithm assumes that list contains the root node.
  // The first step is to add the root node to the list.
  // Is the first page the root path (https://example.com)? Use it as the root node.
  if (isRootPath(childrenPages[0])) {
    const rootPage = childrenPages.shift();

    list.push(rootPageItem(rootPage));
  } else {
    // If the first page is not the root (https://example.com) then we need to create some folders to represent
    // the path to the first page
    list.push(...rootFolderItem(childrenPages[0].url));
  }
  // We index the root node by url so that we can find it later
  list.forEach((item) => {
    if (item.url) {
      nodeByUrl[item.url] = item;
    }
  });

  childrenPages.forEach((page, index) => {
    const item = {
      pageId: page.id,
      url: page.url,
      screenshot: page.screenshotLink || page.screenshotPath, // support old format
      thumbnail: page.screenshotThumbLink, // support old format
    };
    item.parent = findPageParentId(page, list, nodeByUrl);
    item.parentUrl = page.parent || '';
    item.path = pagePath(page);
    item.name = pageIsAFolder(page, index, childrenPages)
      ? item.url
          .split('/')
          .filter((u) => u !== '')
          .pop()
      : page.pageTitle;
    item.id = nextListId(list);
    list.push(item);
    nodeByUrl[item.url] = item;
  });

  const referralRootPage = pickReferralRootPage(pages, rootUrl, redirects);
  // Don't have to build referral tree. Bail out.
  if (referralRootPage) {
    // set alt parent to switch between tree organized by path to tree organized by referral
    buildReferralTree(list, referralRootPage, nodeByUrl, redirects);
  } else {
    // delete parentUrl because it's not needed anymore
    list.forEach((item) => {
      delete item.parentUrl;
    });
  }

  return list;
}

export const pagesToNodes = organizeByPath;
